import { graphql } from "react-apollo";
// @ts-ignore
import { ROUTER } from "../graphql";
import { compose, withProps } from "recompose";
import mapInput from "@gqlapp/base/utils/mapInput";
/**
 * @var client graphql
 * @param fetchPolicy
 * @param variables
 */ export const getRoute = function() {
    let { fetchPolicy , variables  } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return (client)=>client.query({
            query: ROUTER,
            fetchPolicy: fetchPolicy || "cache-first",
            variables: variables || {}
        });
};
/**
 *
 * @param Component
 */ export const withRouteQuery = (input)=>(Component)=>compose(withProps((props)=>mapInput(input, props)), // withProps(({ asPath, routeSkip }: any) => error({ asPath, routeSkip })),
        graphql(ROUTER, {
            skip: (param)=>{
                let { routeSkip , asPath  } = param;
                return !asPath || routeSkip;
            },
            options: (param)=>{
                let { asPath: path , load  } = param;
                path = path || "/";
                path = "".concat(path).replace(".html", "");
                return {
                    variables: {
                        path,
                        load: !!load
                    }
                };
            },
            props: async (param)=>{
                let { data: { loading , route: content  } , ownProps: { route  }  } = param;
                if (!loading) {
                    route.save(content || {});
                }
                return {
                    loading,
                    route
                };
            }
        }))(Component);

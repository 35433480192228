import Page, { getInitialProps } from "@vinhxuan/router/page";
export default function Home(props) {
    return Page(props);
}
Home.getInitialProps = (props)=>{
    return getInitialProps({
        ...props,
        route_type: "TypeIsFront",
        load: true
    });
};
